import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchNotifications(ctx, params) {
      return useJwt.getNotifications(params).then(response => response);
    },
    fetchSystemNotifications(ctx, params) {
      return useJwt.getSystemNotifications(params).then(response => response);
    },
    createNotification(ctx, data) {
      return useJwt.createNotification(data).then(response => response);
    },
    updateNotification(ctx, data) {
      return useJwt.updateNotification(data).then(response => response);
    },
    deleteNotifications(ctx, data) {
      return useJwt.deleteNotifications(data).then(response => response);
    },
    getNotificationDetail(ctx, notificationId) {
      return useJwt.getNotificationDetail(notificationId).then(response => response);
    },
  },
};
