import { ref, watch } from '@vue/composition-api';
import store from '@/store';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import toast from '@/utils/toast';
import { isEnableBed } from '@/auth/utils';

export default function useSelectRecipentModal(props) {
  // Use toast
  const toastification = toast();
  const { t } = useI18nUtils();

  // Table Handlers
  const columns = [
    {
      label: t('Mã'),
      field: 'code',
    },
    {
      label: t('Họ tên'),
      field: 'name',
    },
    {
      label: t('SĐT'),
      field: 'phone',
    },
    {
      label: t('Email'),
      field: 'email',
    },
    {
      label: t('Tòa nhà'),
      field: 'apartment',
    },
  ];
  const rows = ref([]);
  // filter
  const apartment = ref(null);
  const room = ref(null);
  const bed = ref(null);
  const status = ref(null);
  const foreign = ref(null);
  const individual = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {
      status: 0,
    },
    sort: {},
    page: 1,
    perPage: 10000,
  });

  //   API Call
  const fetchTenants = () => {
    isLoading.value = true;
    store
      .dispatch('contract/fetchTenants', serverParams.value)
      .then(response => {
        const { items, total } = response.data;
        totalRecords.value = total;
        if (props.tenantsSelected && props.tenantsSelected.length) {
          const listIdTenantSelected = props.tenantsSelected.map(tenant => tenant.id);
          const dataRes = items.filter(res => !listIdTenantSelected.includes(res.id));
          rows.value = [...dataRes];
        } else rows.value = [...items];
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchTenants();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const resetValue = () => {
    rows.value = [];
    // filter
    apartment.value = null;
    room.value = null;
    bed.value = null;
    status.value = null;
    foreign.value = null;
    individual.value = null;
    // current selected rows
    searchTerm.value = '';
    selectedRows.value = [];
    // ssr
    isLoading.value = false;
    totalRecords.value = 0;
    serverParams.value = {
      searchTerm: '',
      filter: {
        status: 0,
      },
      sort: {},
      page: 1,
      perPage: 20,
    };
  };

  //   Watch

  watch(apartment, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.apartmentId = val.id;
    } else {
      delete filter.apartmentId;
    }
    updateParams({ filter });
  });
  watch(room, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.roomId = val.id;
    } else {
      delete filter.roomId;
    }
    updateParams({ filter });
  });

  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });

  // UI
  const resolveApartmentName = val => {
    if (val && val.length > 0) {
      const item = val[0];
      const arr = [];
      if (item.apartment) {
        arr.push(`Tòa nhà: ${item.apartment.name}`);
      }
      if (item.room) {
        arr.push(`Phòng: ${item.room.name}`);
      }
      return arr.join(", ");
    }
    return "";
  };

  const resolveColWidthIfDisableBed = isBed => {
    if (isEnableBed()) {
      return 4;
    }
    if (isBed) {
      return 0;
    }
    return 6;
  };

  return {
    columns,
    rows,
    apartment,
    room,
    bed,
    status,
    foreign,
    individual,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,

    fetchTenants,
    t,
    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    resetValue,
    selectionChanged,
    resolveApartmentName,
    resolveColWidthIfDisableBed,
  };
}
